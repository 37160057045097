/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/popper.js@1.16.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.5.0/dist/js/bootstrap.min.js
 * - /npm/@fortawesome/fontawesome-free@5.6.3/js/all.min.js
 * - /npm/aos@2.3.1/dist/aos.min.js
 * - /npm/swiper@6.2.0/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
